






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class QuestionGroupForm extends Vue {
  @Prop() private readonly html!: String
}
