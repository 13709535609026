


























import { Component, Vue, Prop } from 'vue-property-decorator'
import Ticket, { TicketData } from '@/components/common/Ticket.vue'
import Message from '@/components/common/Message.vue'

@Component({
  components: {
    Ticket,
    Message
  }
})
export default class Checkpoint extends Vue {
  @Prop() private readonly locationLabel!: string
  @Prop() private readonly ticket!: TicketData
  @Prop() private readonly pointsReported!: boolean
}
