



















import { Component, Vue } from 'vue-property-decorator'
import Page from '@/components/layout/Page.vue'
import Card from '@/components/layout/Card.vue'
import Share from '@/components/Share.vue'
import store from '@/store'

@Component({
  components: {
    Page,
    Card,
    Share
  }
})
export default class Home extends Vue {
  get bodyText() {
    return store.state.configuration.messages.startPageContent
  }
  get authCode() {
    return store.state.profile.authCode
  }
  get groupAppLink() {
    return store.state.profile.groupAppLink
  }
  get baseAppLink() {
    return store.state.profile.baseAppLink
  }
}
