




















































import copy from 'copy-to-clipboard';
import QrcodeVue from 'qrcode.vue'

import { Component, Vue, Prop } from 'vue-property-decorator'
import Fullscreen from '@/components/common/Fullscreen.vue'
import Button from '@/components/common/Button.vue'
import Message from '@/components/common/Message.vue'

@Component({
  components: {
    Button,
    QrcodeVue,
    Fullscreen,
    Message
  }
})
export default class Share extends Vue {
  @Prop({ default: '' }) private readonly authLink!: string
  @Prop({ default: '' }) private readonly baseLink!: string
  @Prop({ default: '' }) private readonly authCode!: string
  private copySucceeded: boolean = false
  private showQrCode: boolean = false

  copyAuthLink() {
    this.copySucceeded = copy(this.authLink)
  }

  openQrCodeScreen() {
    this.showQrCode = true
  }
  closeQrCodeScreen() {
    this.showQrCode = false
  }
}
