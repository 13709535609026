




















import { Component, Vue } from 'vue-property-decorator'
import LinkExternal from '@/components/common/LinkExternal.vue'

@Component({
  components: {
    LinkExternal
  }
})
export default class LocationHelpAndroid extends Vue {
}
