














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class QuestionName extends Vue {
  @Prop({ default: 'Uppgift' }) private readonly name!: string
  @Prop({ default: 0 }) private readonly scoreMax!: string

  get scoreMaxMessage(): string {
    const scoreMax = this.scoreMax
    return scoreMax ? `(${scoreMax} p)` : ''
  }
}
