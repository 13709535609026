














import { Component, Vue } from 'vue-property-decorator'
import Loader from '@/components/common/Loader.vue'
import Button from '@/components/common/Button.vue'

@Component({
  name: 'AppUpdatePending',
  components: {
    Loader,
    Button,
  }
})
export default class AppUpdatePending extends Vue {
  private isUpdateClicked: boolean = false

  onUpdateApp() {
    this.isUpdateClicked = true
    this.$emit('update-clicked')
  }
}
