
export enum Platform {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    UNKNOWN = 'UNKNOWN'
}

export const getPlatform = () : Platform => {
    const userAgent = navigator.userAgent
    if (/android/i.test(userAgent)) {
        return Platform.ANDROID
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return Platform.IOS
    } else {
        return Platform.UNKNOWN
    }
}