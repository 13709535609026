












import { Component, Vue, Prop } from 'vue-property-decorator'
import { Status } from '@/store'

@Component({
  components: {
  }
})
export default class StepbystepProgress extends Vue {
  @Prop() private currentIndex!: number
  @Prop({ default: [] }) private statuses!: Status[]

  get stepStatuses(): any[] {
    return this.statuses
      .map((status, key) => ({
        key,
        status,
        active: this.currentIndex === key
      }))
  }

  getClass(active: boolean, status: Status) {
    return `${active ? 'active' : 'inactive'} ${String(Status[status]).toLowerCase()}`
  }
}
