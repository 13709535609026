
























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { ImageData } from './ImagesQuestion.vue'
import IconButton from '@/components/common/IconButton.vue'

@Component({
  components: {
    IconButton
  }
})
export default class ImagesQuestionImage extends Vue {
  @Prop() private imageData!: ImageData
  @Prop() private readOnly!: boolean

  @Emit('image-removed')
  onImageRemoved() {
    return this.imageData
  }
}

