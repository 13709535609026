



















import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import Button from '@/components/common/Button.vue'
@Component({
  components: {
    Button
  }
})
export default class ConfirmationOverlay extends Vue {
  @Prop() private question!: string;
  @Prop() private acceptLabel!: string;
  @Prop() private rejectLabel!: string;

  @Emit('accept')
  onUserAccept() {
    return true
  }

  @Emit('reject')
  onUserReject() {
    return true
  }
}
