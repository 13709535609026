







































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class Profile extends Vue {
  @Prop({ default: '' }) private readonly groupName!: string
  @Prop({ default: '' }) private readonly categoryName!: string
  @Prop({ default: '' }) private readonly countCompeting!: string
  @Prop({ default: '' }) private readonly countFollower!: string
  @Prop({ default: '' }) private readonly groupPortalLink!: string
}
