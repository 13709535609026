



















import { Component, Vue, Emit } from 'vue-property-decorator'
import IconButton from '@/components/common/IconButton.vue'

@Component({
  components: { IconButton }
})
export default class Fullscreen extends Vue {
  get isCloseEventListenerDefined() {
    return this.$listeners && this.$listeners.close
  }

  @Emit('close')
  onClose() {
    return true
  }
}
