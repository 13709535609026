













import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class LinkExternal extends Vue {
  @Prop() private href!: string
}
