































































































import { Component, Vue } from 'vue-property-decorator'
import Page from '@/components/layout/Page.vue'
import Card from '@/components/layout/Card.vue'
import Loader from '@/components/common/Loader.vue'
import Message from '@/components/common/Message.vue'
import Button from '@/components/common/Button.vue'
import Fullscreen from '@/components/common/Fullscreen.vue'
import * as Api from '@/utils/Api'

const apiHost = process.env.VUE_APP_API_HOST

@Component({
  components: {
    Page,
    Card,
    Loader,
    Button,
    Fullscreen,
    Message
  }
})
export default class Duels extends Vue {
  private isLoading: boolean = false
  private isWhyMultipleOpponentsOpen: boolean = false
  private isHowToDuelOpen: boolean = false
  private duelsPayload: any = null

  async mounted() {
    this.isLoading = true

    try {
      const duelsResp = await Api.call({
        endpoint: `${apiHost}/wp-json/tuja/v1/duels`
      })
      const duelsPayload = duelsResp.payload
      this.duelsPayload = duelsPayload
    } catch (e: any) {

    }
    this.isLoading = false
  }

  toggleWhyMultipleOpponents() {
    this.isWhyMultipleOpponentsOpen = !this.isWhyMultipleOpponentsOpen
  }

  toggleHowToDuel() {
    this.isHowToDuelOpen = !this.isHowToDuelOpen
  }

  toLocalTime(isoDateString: string) {
    return new Date(isoDateString).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  }
}
