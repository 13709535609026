










































import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/common/Button.vue'
import Loader from '@/components/common/Loader.vue'
import Message from '@/components/common/Message.vue'
import Card from '@/components/layout/Card.vue'
import * as AuthUtils from '@/utils/Auth'
import * as Api from '@/utils/Api'
import * as Analytics from '@/utils/Analytics'

const apiHost = process.env.VUE_APP_API_HOST

enum GetTokenStatus {
  NOT_STARTED,
  PENDING,
  SUCCESS,
  FAILURE,
}

type LoginParams = {
  code?: string
  id?: string
}

@Component({
  name: 'AppLogin',
  components: {
    Button,
    Loader,
    Card,
    Message,
  }
})
export default class AppLogin extends Vue {
  private password: string = ''
  private tokenStatus: GetTokenStatus = GetTokenStatus.NOT_STARTED;
  private errorMessage: string = ''

  get isSubmitting(): boolean {
    return this.tokenStatus === GetTokenStatus.PENDING
  }

  get isGroupKeySet() {
    return !!this.$route.query.id
  }

  async onSubmitPassword() {
    await this.logIn({ code: this.password })
  }

  setToken(token: string | null) {
    if (token) {
      AuthUtils.setTokenCookie(token)
    } else {
      AuthUtils.unsetTokenCookie()
    }
  }

  async logIn(params: LoginParams) {
    this.errorMessage = ''
    this.tokenStatus = GetTokenStatus.PENDING
    const analyticsEventObject = params.code ? 'login_with_pin' : 'login_with_link'
    try {
      const resp = await Api.call({
        endpoint: `${apiHost}/wp-json/tuja/v1/tokens`,
        method: 'POST',
        payload: params,
        unauthenticated: true
      })
      const payload = resp.payload
      this.setToken(payload.token)


      this.tokenStatus = GetTokenStatus.SUCCESS
      this.$emit('success')

      Analytics.logEvent(Analytics.AnalyticsEventType.AUTH, 'succeeded', analyticsEventObject)
    } catch (e: any) {
      if (e instanceof Api.ApiError) {
        this.setToken(null)
        this.tokenStatus = GetTokenStatus.FAILURE
        switch (e.status) {
          case 400:
            this.errorMessage = 'Något stämmer inte. Har du rätt antal siffror?'
            break;
          case 401:
            this.errorMessage = 'Det gick inte att logga in. Dubbelkolla PIN-koden.'
            break;
          default:
            this.errorMessage = 'Något gick fel. Kontakta kundtjänst om det händer igen.'
            break;
        }
      } else {
        this.errorMessage = 'Något gick fel. ' + (e.message ? `Fel: ${e.message}` : '')
      }
      Analytics.logEvent(Analytics.AnalyticsEventType.AUTH, 'failed', analyticsEventObject, {
        message: this.errorMessage,
      })
      this.$emit('failure')
    }
  }

  async mounted() {
    const authId = String(this.$route.params.authId || '')
    if (authId) {
      await this.logIn({ id: authId })
    }
  }
}
