




























import { Component, Vue, Prop } from 'vue-property-decorator'

export type TicketData = {
  key: string
  colour: string
  stationName: string
  markerName?: string
  word: string
  isUsed: boolean
}

@Component({
  components: {
  }
})
export default class Ticket extends Vue {
  @Prop() private ticket!: TicketData

  get wordFontSize(): number {
    return Math.min(25, 100 / this.ticket.word.length)
  }

  get isUsed(): boolean {
    return this.ticket.isUsed
  }

  get wrapperClass(): string {
    return 'ticket-wrapper ' + (this.isUsed ? 'ticket-used' : 'ticket-unused')
  }
}
