




































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { QuestionDto, ExtendedQuestionGroupDto } from '@/components/common/question/model'
import Card from '@/components/layout/Card.vue'
import QuestionForm from '@/components/QuestionForm.vue'
import Bodytext from '@/components/common/Bodytext.vue'

@Component({
  components: {
    QuestionForm,
    Bodytext,
    Card
  }
})
export default class QuestionGroupForm extends Vue {
  @Prop() private questionGroup!: ExtendedQuestionGroupDto

  questionKey(question: QuestionDto) {
    return JSON.stringify({
      r: question.response,
      c: question.config
    })
  }

  @Emit('submit-success')
  onSubmitSuccess(updatedQuestionData: QuestionDto) {
    const index = this.questionGroup.questions.findIndex(q => q.id === updatedQuestionData.id)
    if (index !== -1) {
      this.questionGroup.questions.splice(index, 1, updatedQuestionData)
    }
    return updatedQuestionData
  }

  onPostViewEventSuccess(questionId: string) {
    const qId = parseInt(questionId, 10)
    const index = this.questionGroup.questions.findIndex(q => q.id === qId)
    if (index !== -1) {
      this.questionGroup.questions[index].view_event.is_found = true
    }
    return questionId
  }

  onQuestionFetched(updatedQuestionData: QuestionDto) {
    const index = this.questionGroup.questions.findIndex(q => q.id === updatedQuestionData.id)
    if (index !== -1) {
      this.questionGroup.questions.splice(index, 1, updatedQuestionData)
    }
  }
}
