
























































































































import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/common/Button.vue'
import Loader from '@/components/common/Loader.vue'
import * as AuthUtils from '@/utils/Auth'
import * as ConfigurationUtils from '@/utils/Configuration'
import store from '@/store'

@Component({
  name: 'AppMain',
  components: {
    Button,
    Loader,
  }
})
export default class AppMain extends Vue {
  private confPollTimer = 0
  private showMore = false

  routerPathPrefix() {
    const groupKey = AuthUtils.getGroupKey()
    if (groupKey) {
      return `/${groupKey}`
    }
    return ''
  }

  get isAnswerPageEnabled() {
    return store.state.configuration.views.answer
  }
  
  get isCheckinPageEnabled() {
    return store.state.configuration.views.checkin
  }

  get isMapPageEnabled() {
    return store.state.configuration.views.map
  }

  get isDeviceTestPageEnabled() {
    return store.state.configuration.views.deviceTest
  }

  get isTicketsPageEnabled() {
    return store.state.configuration.views.tickets
  }

  get isDuelsPageEnabled() {
    return store.state.configuration.views.duels
  }

  get isInfoPageEnabled() {
    return store.state.configuration.views.info
  }

  get showMoreButtonClass() {
    return this.showMore ? 'show' : 'hide'
  }

  toggleShowMore() {
    this.showMore = !this.showMore
  }

  onMenuChildClick(event: any) {
    const routerLinkElement = event.target.closest('div.router-link-active')
    if (routerLinkElement) {
      // Router Link was clicked. Hide menu.
      this.showMore = false
    }
  }

  async fetchConfiguration() {
    try {
      const conf = await ConfigurationUtils.fetchConfiguration()
      store.setConfiguration(conf)
    } catch (e: any) {

    }
  }

  async pollJob() {
    await this.fetchConfiguration()

    const pollInterval = (store.state.configuration.updates.configPollInterval || 60)

    this.$emit('poll-interval-update', pollInterval)

    console.log(`Will fetch configuration in ${pollInterval} seconds.`)
    this.confPollTimer = setTimeout(this.pollJob, pollInterval * 1000)
  }

  async initConfigurationPoll() {
    await this.pollJob()
  }

  async mounted() {
    await this.initConfigurationPoll()
  }

  beforeDestroy() {
    if (this.confPollTimer) {
      clearTimeout(this.confPollTimer)
    }
  }
}
