







import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/common/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class Intro extends Vue {
  onStart() {
    return true
  }
}
