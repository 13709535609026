









import { AccuracyLevel } from '@/utils/Location';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class AccuracyIcon extends Vue {
  @Prop()
  readonly accuracy!: AccuracyLevel;

  get wrapperClass(): string {
    return `bars accuracy-${AccuracyLevel[this.accuracy]}`
  }


  get barLowClass() {
    const isActive = true//this.accuracy === AccuracyLevel.LOW
    return `bar-LOW active-${isActive}`
  }

  get barMediumClass() {
    const isActive = this.accuracy !== AccuracyLevel.LOW
    return `bar-MEDIUM active-${isActive}`
  }

  get barHighClass() {
    const isActive = this.accuracy === AccuracyLevel.HIGH || this.accuracy === AccuracyLevel.HIGHEST
    return `bar-HIGH active-${isActive}`
  }

  get barHighestClass() {
    const isActive = this.accuracy === AccuracyLevel.HIGHEST
    return `bar-HIGHEST active-${isActive}`
  }

}
