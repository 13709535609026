











































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Page from '@/components/layout/Page.vue'
import Card from '@/components/layout/Card.vue'
import Loader from '@/components/common/Loader.vue'
import Message from '@/components/common/Message.vue'
import Button from '@/components/common/Button.vue'
import StepbystepProgress from '@/components/common/StepbystepProgress.vue'
import Fullscreen from '@/components/common/Fullscreen.vue'
import OptionsQuestion from '@/components/common/question/OptionsQuestion.vue'
import * as Api from '@/utils/Api'
import * as Analytics from '@/utils/Analytics'
import { FormUpdate } from '@/components/common/question/model'

const apiHost = process.env.VUE_APP_API_HOST

@Component({
  components: {
    Page,
    Card,
    Loader,
    Button,
    Fullscreen,
    Message,
    StepbystepProgress,
    OptionsQuestion
  }
})
export default class Checkin extends Vue {
  private isLoading: boolean = false
  private isCheckinPending: boolean = false
  private isCheckinCompleted: boolean = false
  private checkinPayload: any = null
  private currentStep = 0
  private showSomethingWrongPopup: boolean = false

  private selectedCompetingParticipants: Number[] = []
  private selectedAdultSupervisors: Number[] = []

  private checkinErrorTitle: string = ''
  private checkinErrorMessage: string = ''

  async mounted() {
    this.isLoading = true

    this.currentStep = parseInt(this.$route.params?.stepNumber || '1')

    try {
      const checkinResp = await Api.call({
        endpoint: `${apiHost}/wp-json/tuja/v1/checkin`
      })
      const checkinPayload = checkinResp.payload
      this.checkinPayload = checkinPayload
      this.isCheckinCompleted = checkinPayload.status === 'checkedin'
    } catch (e: any) {

    }
    this.isLoading = false
  }

  get groupName() {
    return this.checkinPayload?.group_name
  }

  get categoryName() {
    return this.checkinPayload?.category_name
  }

  get somethingWrongTitle() {
    return this.checkinPayload?.messages.something_wrong.title
  }

  get somethingWrongBodyHtml() {
    return this.checkinPayload?.messages.something_wrong.body_text
  }

  get checkingSuccessfulTitle() {
    return this.checkinPayload?.messages.checkin_done.title
  }

  get checkingSuccessfulBodyHtml() {
    return this.checkinPayload?.messages.checkin_done.body_text
  }

  get competingParticipants() {
    return this.checkinPayload.participants.filter(({ is_competing: isCompeting }: any): any => !!isCompeting)
  }

  get adultSupervisors() {
    return this.checkinPayload.participants.filter(({ is_adult_supervisor: isAdultSupervisor }: any): any => !!isAdultSupervisor)
  }

  get competingParticipantsQuestionResponse() {
    return { field_name: 'competingParticipants' }

  }

  get competingParticipantsQuestionConfig() {
    return {
      is_single_select: false,
      possible_answers: this.competingParticipants.map(this.displayName)
    }
  }

  get adultSupervisorsQuestionResponse() {
    return { field_name: 'adultSupervisors' }

  }

  get adultSupervisorsQuestionConfig() {
    return {
      is_single_select: false,
      possible_answers: this.adultSupervisors.map(this.displayName)
    }
  }

  get isAdultSupervisorDefined(): boolean {
    return this.adultSupervisors.length > 0
  }

  displayName({ name, phone }: any) {
    return phone ? `${name}, ${phone}` : name
  }

  openSomethingWrongPopup() {
    this.showSomethingWrongPopup = true
  }

  closeSomethingWrongPopup() {
    this.showSomethingWrongPopup = false
  }

  onSomethingWrong() {
    this.openSomethingWrongPopup()
  }

  @Watch('$route')
  onRouteChange(e: any) {
    this.currentStep = parseInt(e.params?.stepNumber || '1')
  }

  onBack() {
    this.$router.push({ name: 'Checkin', params: { stepNumber: '1' } })
  }

  onContinue() {
    this.$router.push({ name: 'Checkin', params: { stepNumber: '2' } })
  }

  getPersonIdsFromFormUpdate(people: any[], update: FormUpdate) {
    return people
      .filter((person: any) => update.updatedFields.some(update => update.value === this.displayName(person)))
      .map(({ id }: any) => id)
  }

  onCompetingParticipantsChange(update: FormUpdate) {
    this.selectedCompetingParticipants = this.getPersonIdsFromFormUpdate(this.competingParticipants, update)
  }

  onAdultSupervisorsChange(update: FormUpdate) {
    this.selectedAdultSupervisors = this.getPersonIdsFromFormUpdate(this.adultSupervisors, update)
  }

  async onCheckin() {
    this.isCheckinPending = true
    this.checkinErrorTitle = ''
    this.checkinErrorMessage = ''

    try {
      const resp = await Api.call({
        endpoint: `${apiHost}/wp-json/tuja/v1/checkin`,
        method: 'POST',
        payload: { people_ids: [...this.selectedAdultSupervisors, ...this.selectedCompetingParticipants] },
      })
      this.isCheckinCompleted = true
    } catch (e: any) {
      if (e instanceof Api.ApiError) {
        switch (e.status) {
          case 400:
            this.checkinErrorTitle = 'Du missade något'
            this.checkinErrorMessage = 'Kryssade du för alla som är med?'
            break;
          default:
            this.checkinErrorTitle = 'Det gick inte att checka in'
            this.checkinErrorMessage = 'Kontakta kundtjänst om felet återkommer.'
            break;
        }
        Analytics.logEvent(Analytics.AnalyticsEventType.MISC, 'failed', 'checkin', {
          message: 'Group could not check in. Reason: Non-ok http response.',
          status: `Http response ${e.status}.`
        })
      } else {
        this.checkinErrorTitle = 'Det gick inte att checka in'
        this.checkinErrorMessage = 'Kontakta kundtjänst om felet återkommer.'
        Analytics.logEvent(Analytics.AnalyticsEventType.MISC, 'failed', 'checkin', {
          message: `Group could not check in. Reason: ${e.message}.`
        })
      }

    } finally {
      this.isCheckinPending = false
    }
  }

}
