




























import { Component, Vue, Prop } from "vue-property-decorator";
import IconButton from "@/components/common/IconButton.vue";
import store from "@/store";
import { AnalyticsEventType, AppEvent, LogLevel } from "@/utils/Analytics";
const LOG_LEVEL_MAPPING = {
  [LogLevel.INFO]: "info",
  [LogLevel.DEBUG]: "debug",
  [LogLevel.WARNING]: "warning",
  [LogLevel.ERROR]: "error"
};
@Component({
  components: {
    IconButton
  }
})
export default class DebugPopup extends Vue {
  private isExpanded = false;

  get events() {
    return store.getEvents().map((event: AppEvent) => ({
      ...event,
      props: JSON.stringify(event.props, null, 2),
      type: AnalyticsEventType[event.type].toLowerCase(),
      level: LogLevel[event.level].toLowerCase()
    }));
  }

  get cssClasses() {
    return "debug-window " + (this.isExpanded ? "expanded" : "collapsed");
  }

  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
