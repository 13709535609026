












































import { Component, Vue } from 'vue-property-decorator'
import LinkExternal from '@/components/common/LinkExternal.vue'

const IOS_SETTTINGS_LINK = 'App-prefs://prefs:root='

@Component({
  components: {
    LinkExternal
  }
})
export default class LocationHelpIos extends Vue {
  get iosSettingsLink(): string {
    return IOS_SETTTINGS_LINK
  }
}
