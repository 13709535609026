



















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Loader extends Vue {
  @Prop() private message!: string
  @Prop() private white!: boolean
  @Prop({ default: 'normal' }) private size!: string

  get wrapperClasses() {
    return `size-${this.size} color-${this.white ? "white" : "black"}`
  }
}
