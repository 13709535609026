









































import { Component, Vue } from 'vue-property-decorator'
import * as Analytics from '@/utils/Analytics'
import Button from '@/components/common/Button.vue'
import store from '@/store'
import * as AuthUtils from '@/utils/Auth'
import Page from '@/components/layout/Page.vue'
import Card from '@/components/layout/Card.vue'
import Message from '@/components/common/Message.vue'

const APP_VERSION = process.env.VUE_APP_VERSION

@Component({
  components: {
    Page,
    Card,
    Message,
    Button
  }
})
export default class Settings extends Vue {
  private isFullGroupIdShown: boolean = false

  get debugMap() {
    return store.state.debugSettings.map
  }

  get groupId() {
    const groupKey = AuthUtils.getGroupKey()
    if (!groupKey) {
      return null
    }
    const deviceId = Analytics.getDeviceId() ?? ''
    return this.isFullGroupIdShown ? `${groupKey}-${deviceId}` : `${groupKey.substring(0, 5).toUpperCase()}-${deviceId.substring(0, 5).toUpperCase()}`
  }

  get debugConsole() {
    return store.state.debugSettings.console
  }

  get autoSave() {
    return store.state.autoSave
  }

  set autoSave(value: boolean) {
    store.setFormAutoSave(value)
  }

  set debugMap(value: boolean) {
    store.setDebugMap(value)
  }

  set debugConsole(value: boolean) {
    store.setDebugConsole(value)
  }

  get isLoggedIn(): boolean {
    return AuthUtils.isLoggedIn()
  }

  get appVersion(): string {
    return APP_VERSION
  }

  toggleFullGroupIdShown() {
    this.isFullGroupIdShown = !this.isFullGroupIdShown
  }

  onLogOut() {
    if (confirm('Vill du logga ut?')) {
      AuthUtils.unsetTokenCookie()
      this.$router.push({ path: '/' })
    }
  }
}
