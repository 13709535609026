













import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import QuestionForm from '@/components/QuestionForm.vue'
import { QuestionDto } from '@/components/common/question/model'

@Component({
  components: {
    QuestionForm
  }
})
export default class CheckpointQuestion extends Vue {
  @Prop() private readonly questionId!: string
  @Prop() private readonly duelName!: string
  @Prop() private readonly readOnly!: boolean

  @Emit('submit-success')
  onSubmitSuccess(updatedQuestionData: QuestionDto) {
    return updatedQuestionData
  }

  @Emit('submit-failure')
  onSubmitFailure(error: any) {
    return error
  }
}
