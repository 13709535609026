
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Page extends Vue {
    @Prop() private title!: string
    @Prop() private noPadding!: boolean

    get isFooterDefined() {
      return !!this.$slots.footer
    }

    get bodyClasses() {
      return this.noPadding ? 'body no-padding' : 'body'
    }
}
