






























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import Page from '@/components/layout/Page.vue'
import Wrapper from './Question.vue'
import { FormUpdate, QuestionResponseDto } from './model'

@Component({
  components: {
    Page,
    Wrapper
  }
})
export default class OptionsQuestion extends Vue {
  @Prop() private questionResponse!: QuestionResponseDto;
  @Prop() private questionConfig!: any;
  @Prop() private readOnly!: boolean;

  private isSingleSelect: boolean = false
  private fieldValue: string = ''
  private fieldValues: string[] = []

  get possibleAnswers() {
    return this.questionConfig?.possible_answers || []
  }

  get fieldName() {
    return (
      this.questionResponse.field_name +
      (!this.isSingleSelect ? '[]' : '')
    )
  }

  created() {
    const inputValue = this.questionResponse &&
      this.questionResponse.current_value
      ? this.questionResponse.current_value
      : []
    const singleSelect = this.questionConfig?.is_single_select
    if (singleSelect) {
      this.fieldValue = inputValue[0]
      this.$watch('fieldValue', this.onChangeRadio)
    } else {
      this.fieldValues = inputValue
      this.$watch('fieldValues', this.onChangeCheckbox)
    }
    this.isSingleSelect = singleSelect
  }

  createFormUpdateObject(values: string[]): FormUpdate {
    return {
      updatedFields: values.map((value: string) => ({
        key: this.fieldName,
        value
      }))
    } as FormUpdate
  }

  @Emit('change')
  onChangeRadio() {
    return this.createFormUpdateObject([this.fieldValue])
  }

  @Emit('change')
  onChangeCheckbox() {
    return this.createFormUpdateObject(this.fieldValues)
  }
}
