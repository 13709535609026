






import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import Button from '@/components/common/Button.vue'
@Component({
  components: {
    Button
  }
})
export default class NotificationOverlay extends Vue {
  @Prop() private message!: string;
}
