



























import { Component, Vue, Prop } from 'vue-property-decorator'
import Loader from '@/components/common/Loader.vue'

export enum Type {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export enum Size {
  NORMAL = 'normal',
  HUGE = 'huge'
}

@Component({
  components: {
    Loader
  }
})
export default class Button extends Vue {
  @Prop() private label!: string
  @Prop() private pending!: boolean
  @Prop({ default: Type.PRIMARY }) readonly type!: Type
  @Prop({ default: Size.NORMAL }) readonly size!: Size
  @Prop({ default: false }) readonly wide!: boolean
  @Prop() private readonly icon!: string;

  onClick() {
    if (!this.pending) {
      this.$emit('click')
      return true
    }
  }

  get isPrimary(): boolean {
    return this.type === Type.PRIMARY
  }

  get buttonClass(): string {
    return `type-${this.type} size-${this.size} pending-${this.pending ? 'yes' : 'no'} width-${this.wide ? 'full' : 'auto'}`
  }
}
