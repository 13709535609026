








































import { Component, Vue, Prop } from 'vue-property-decorator'

export enum Type {
  INFO = 'info',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

const icons: Record<Type, string> = {
  [Type.INFO]: 'info-circle',
  [Type.SUCCESS]: 'check-circle',
  [Type.FAILURE]: 'exclamation-triangle',
}

const iconColors: Record<Type, string> = {
  [Type.INFO]: '#7c8da1',
  [Type.SUCCESS]: '#85a17c',
  [Type.FAILURE]: '#bd8383',
}

@Component({
  components: {}
})
export default class Message extends Vue {
  @Prop() private readonly message!: string;
  @Prop() private readonly header!: string;
  @Prop() private readonly headerIcon!: string;
  @Prop({ default: Type.INFO }) private readonly type!: Type;

  get containerClass(): string {
    return `message-container type-${this.type}`
  }

  get icon(): string {
    return icons[this.type]
  }

  get iconColor(): string {
    return iconColors[this.type]
  }

  get isExtraContentDefined() {
    return !!this.$slots.default
  }

}
