import { render, staticRenderFns } from "./Duels.vue?vue&type=template&id=c79e605a&scoped=true&"
import script from "./Duels.vue?vue&type=script&lang=ts&"
export * from "./Duels.vue?vue&type=script&lang=ts&"
import style0 from "./Duels.vue?vue&type=style&index=0&id=c79e605a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79e605a",
  null
  
)

export default component.exports