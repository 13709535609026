





































import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/common/Button.vue'
import IconButton from '@/components/common/IconButton.vue'
import ConfirmationOverlay from '@/components/common/ConfirmationOverlay.vue'
import Fullscreen from '@/components/common/Fullscreen.vue'
import CameraComponent from '@/components/common/Camera.vue'
import store, { Status } from '@/store'

@Component({
  components: {
    Button,
    IconButton,
    Fullscreen,
    CameraComponent,
    ConfirmationOverlay
  }
})
export default class Camera extends Vue {
  private isCameraShown = false;

  private img?= '';

  get startTestButtonLabel() {
    return [Status.PENDING, Status.USER_INTERACTION_REQUIRED].includes(store.state.deviceTest.camera.status)
      ? 'Testa kamera'
      : 'Testa kamera igen'
  }

  get startTestButtonType() {
    return [Status.PENDING, Status.USER_INTERACTION_REQUIRED].includes(store.state.deviceTest.camera.status)
      ? 'primary'
      : 'secondary'
  }

  onStartTest() {
    this.isCameraShown = true
    this.img = ''
    // this.start()
  }

  onEndTest() {
    this.isCameraShown = false
  }

  onCaptured(event: string) {
    this.img = event
  }

  onRejectPhoto() {
    this.img = ''
    // this.start()
  }

  onAcceptPhoto() {
    store.setDeviceTestStatus('camera', Status.SUCCESS)
    this.onEndTest()
  }
}
